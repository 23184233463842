import styled from '@emotion/styled';
import { theme } from '../../styles';

export const TeamMemberLayoutContainer = styled.div`
  margin-top: 45px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 72px;
  }
`;

export const TeamMemberLayoutHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (min-width: ${theme.breakpoints.xs}) {
    flex-direction: row;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 80px;
  }

  .TeamMember__Img {
    width: 100%;

    @media (min-width: ${theme.breakpoints.xs}) {
      width: 50%;
    }
  }
`;

export const TeamMemberLayoutContent = styled.div`
  padding-top: 20px;
  width: 100%;

  @media (min-width: ${theme.breakpoints.xs}) {
    padding: 20px;
    width: 50%;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    padding: 40px;
  }

  .TeamMember_Title {
    margin-bottom: 10px;
  }

  .ProjectTile__Position {
    margin-bottom: 10px;
  }
`;

export const TeamMemberLayoutBio = styled.div`
  @media (min-width: ${theme.breakpoints.md}) {
    column-count: 2;
    column-gap: 40px;
  }

  h1 {
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.scale(theme.fonts.xxxl_small)};
    margin-bottom: 10px;
  }

  h2 {
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.scale(theme.fonts.xxl_large)};
    margin-bottom: 10px;
  }

  h3 {
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.scale(theme.fonts.xxl_medium)};
    margin-bottom: 10px;
  }

  h4 {
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.scale(theme.fonts.xxl_small)};
    margin-bottom: 10px;
  }

  h5 {
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.scale(theme.fonts.xl_medium)};
    margin-bottom: 10px;
  }

  h6 {
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.scale(theme.fonts.l)};
    margin-bottom: 10px;
  }

  li {
    padding-left: 25px;
    margin-bottom: 15px;
    font-weight: 400;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: -20px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: ${theme.colors.primaryred};
    }
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: underline;
  }
`;
