import React from 'react';
import Img from 'gatsby-image';
import Title from '../Title/Title';
import SEO from '../SEO';
import Transition from '../Transition/Transition';
import {
  TeamMemberLayoutContainer,
  TeamMemberLayoutHeader,
  TeamMemberLayoutContent,
  TeamMemberLayoutBio,
} from './TeamMemberLayout.css';
import Footer from '../Footer/Footer';

const TeamMemberLayout = ({ data, pageContext: { locale }, location }) => {
  const {
    allPrismicTeamMember: {
      nodes: [{ data: query }],
    },
  } = data;

  return (
    <>
      <SEO location={location} pathname={location.pathname} locale={locale} />
      <Transition>
        <TeamMemberLayoutContainer>
          <TeamMemberLayoutHeader>
            <Img
              fluid={query.image.localFile.childImageSharp.fluid}
              imgStyle={{ objectFit: 'contain' }}
              className='TeamMember__Img'
            />
            <TeamMemberLayoutContent className='TeamMember__Content'>
              {query.name ? (
                <Title type='h2_secondary' customClassName='TeamMember_Title'>
                  {query.name.text}
                </Title>
              ) : (
                ''
              )}
              {query.qualification ? (
                <Title customClassName='ProjectTile__Position'>
                  {query.qualification.text}
                </Title>
              ) : (
                ''
              )}
              {query.position ? (
                <Title customClassName='ProjectTile__Position'>
                  {query.position.text}
                </Title>
              ) : (
                ''
              )}
            </TeamMemberLayoutContent>
          </TeamMemberLayoutHeader>
          <TeamMemberLayoutBio
            className='ArticleTemplate_Body'
            dangerouslySetInnerHTML={{ __html: query.body.html }}
          />
          <Footer />
        </TeamMemberLayoutContainer>
      </Transition>
    </>
  );
};

export default TeamMemberLayout;
