import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import TeamMemberLayout from '../components/PagesLayout/TeamMemberLayout';

const TeamMemberTemplate = (props) => {
  return <TeamMemberLayout {...props} />;
};

TeamMemberTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TeamMemberTemplate;

export const query = graphql`
         query teamMemberQuery($locale: String!, $uid: String!) {
           allPrismicTeamMember(
             filter: { lang: { eq: $locale }, uid: { eq: $uid } }
           ) {
             nodes {
               data {
                 name {
                   text
                 }
                 position {
                   text
                 }
                 qualification {
                   text
                 }
                 image {
                   localFile {
                     childImageSharp {
                       fluid(toFormat: JPG) {
                         ...GatsbyImageSharpFluid_noBase64
                       }
                     }
                   }
                 }
                 body {
                   html
                 }
               }
             }
           }
         }
       `;
